
















import { Component, Vue, Prop } from 'vue-property-decorator'

// Eclipse component img
import eclipse from '@/components/icons/elipse.vue'

import { DocumentPresetResource } from '@/models/documents/DocumentPresetResource'
import { RawLocation } from 'vue-router'

@Component({
  components: {
    eclipse
  }
})
export default class PresetTile extends Vue {

  @Prop()
  private preset!: DocumentPresetResource

  @Prop()
  private presetType!: 'public' | 'organisation'

  private get link(): RawLocation {
    return { name: `${ this.presetType }-documents-overview`, query: { presets: `${ this.preset.id }` } }
  }

}
