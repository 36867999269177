
























import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { ProjectTypeResourceCollection } from '@/models/projectType/ProjectTypeResourceCollection'

@Component
export default class ProjectTypeCard extends Vue {

  @Prop()
  private readonly selected!: number

  @Prop()
  private projectType!: ProjectTypeResourceCollection

  private get processesLabel(): string {
    return `Process${this.projectType.process_count === 1 ? '' : 'es'}`
  }

  private get templatesLabel(): string {
    return `Template${this.projectType.template_count === 1 ? '' : 's'}`
  }

  private get isSelected(): boolean {
    return this.projectType.id === this.selected
  }

  private select(): void {
    this.$emit('select', this.projectType.id)
  }
}
