import { formatDateForBackend } from '@/helpers/formatDate'


export class ProjectCreateRequest {
  public project_name: string = ''
  public end_date: Date | null
  public project_type_id: number = 0

  constructor() {
    this.end_date = null
  }

  public getRequestData(): { project_name: string, end_date: string, project_type_id?: number } {
    return {
      project_name: this.project_name,
      end_date: formatDateForBackend(this.end_date),
      project_type_id: this.project_type_id
    }
  }

}
