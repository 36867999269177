












import { Component, Vue, Prop } from 'vue-property-decorator'

// Eclipse component img
import eclipse from '@/components/icons/elipse.vue'

import { ProjectCollectionResource } from '@/models/projects/ProjectCollectionResource'

@Component({
  components: {
    eclipse
  }
})
export default class ProjectTile extends Vue {

  @Prop()
  private project!: ProjectCollectionResource

}
