









































































































































































import { userModule } from '@/store'
import { stateModule, projectsModule, organisationDocumentsModule } from '@/store'
import { OBJECT_VALUES_TO_STRING } from '@/helpers'
import { ProjectsService } from '@/services/projects'
import { Component, Vue } from 'vue-property-decorator'
import { OrganisationService } from '@/services/organisation'

// Components
import TableRow from '@/components/table/Row.vue'
import ProjectTile from '@/components/tiles/Project.vue'
import Preset from '@/components/tiles/Preset.vue'
import TableHeading from '@/components/table/Heading.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import ProjectTileNew from '@/components/tiles/NewProject.vue'
import Pagination from '@/components/paginations/Pagination.vue'
import SearchInput from '@/components/inputs/Search.vue'
import Modal from '@/components/modals/Default.vue'
import Input from '@/components/inputs/Text.vue'
import InputDate from '@/components/inputs/Date.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProjectType from '@/components/tiles/ProjectType.vue'
import CheckboxInput from '@/components/inputs/Checkbox.vue'
import DefaultModal from '@/components/modals/Default.vue'
import Dropdown from '@/components/inputs/Dropdown.vue'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { ProjectCollectionResource } from '@/models/projects/ProjectCollectionResource'
import { NotificationResource } from '@/models/notifications/NotificationResource'
import { ProjectCreateRequest } from '@/requests/projects/ProjectCreateRequest'
import { ProjectTypeResourceCollection } from '@/models/projectType/ProjectTypeResourceCollection'
import {DocumentPresetResource} from '@/models/documents/DocumentPresetResource'

interface PresetWithPresetType extends DocumentPresetResource {
  type: 'public' | 'organisation'
}

@Component({
  components: {
    TableRow,
    Pagination,
    ProjectTile,
    TableHeading,
    TableWrapper,
    ProjectTileNew,
    SearchInput,
    Modal,
    Input,
    InputDate,
    SmallLoader,
    ProjectType,
    CheckboxInput,
    Preset,
    DefaultModal,
    Dropdown
  },
})
export default class Overview extends Vue {
  // Data
  private namespace: string = 'projects'
  private params: IndexParameters = {
    column: 'created_at',
    dir: 'desc',
    search: '',
    page: '1',
    per_page: '10',
    project_type: null,
  }

  private create: { open: boolean; loading: boolean; form: ProjectCreateRequest; errors: ErrorResponse } = {
    open: false,
    loading: false,
    errors: {},
    form: new ProjectCreateRequest(),
  }
  private exported: boolean = false

  private confirmArchiveProjectsModal: boolean = false

  private project_types: ProjectTypeResourceCollection[] = []
  private projectTypeGroupExpanded: boolean = false

  private service: ProjectsService = new ProjectsService()
  private organisationService: OrganisationService = new OrganisationService()

  private selectedProjects: ProjectCollectionResource[] = []

  private presetPageIndex: number = 1

  private get allCurrentProjectsSelected(): boolean {
    return this.selectedProjects.length > 0 ? !!this.index?.data.every((project) => this.selectedProjects.some((selectedProject) => project.id === selectedProject.id)) : false
  }

  private get selectedArchivableProjects(): ProjectCollectionResource[] {
    return this.selectedProjects.filter(({ permissions }) => permissions.includes('can_archive'))
  }

  private get areAllSelectedProjectsArchivable(): boolean {
    return this.selectedArchivableProjects.length === this.selectedProjects.length
  }

  private get cantArchiveHint(): string | undefined {
    if (this.noProjectsSelected) {
      return 'Please select projects to archive.'
    } else if (!this.areAllSelectedProjectsArchivable) {
      return 'Not all selected projects are archivable.'
    } else {
      return
    }
  }

  private get areAllSelectedProjectsOfSameType(): boolean {
    return this.selectedProjects.every((project) => project.project_type === this.selectedProjects[0]?.project_type)
  }

  private get cantExportHint(): string | undefined {
    if (this.noProjectsSelected) {
      return 'Please select projects to export.'
    } else if (!this.areAllSelectedProjectsOfSameType) {
      return 'Exporting projects of different types are not supported.'
    } else {
      return
    }
  }

  private toggleSelectAll() {
    if (this.allCurrentProjectsSelected) {
      this.selectedProjects = this.selectedProjects.filter((project) => !this.index?.data.some(({id}) => project.id === id))
    } else {
      this.selectedProjects.push(...(this.index?.data ?? []))
    }
  }

  private get mappedProjectTypes(): SelectItem[] {
    return this.project_types.map(({ id, name }) => ({ label: name, value: `${id}` }))
  }

  private get presetPagination(): PaginationInterface {
    return {
      total: this.presetCount,
      per_page: 10,
      current_page: this.presetPageIndex,
      last_page: Math.ceil(this.presetCount / 10),
    }
  }

  // Computed
  private get index(): IndexResponse<ProjectCollectionResource> | null {
    return projectsModule.index
  }

  private get isFetchingPresets(): boolean | undefined {
    return this.user?.isFetchingPresets
  }

  private get presetCount(): number {
    return [...this.presets, ...this.organisationPresets].length
  }

  private get paginatedPresets(): PresetWithPresetType[] {
    const publicPresets = this.presets.map((item) => ({...item, type: 'public'})) as PresetWithPresetType[]
    const organisationPresets = this.organisationPresets.map((item) => ({...item, type: 'organisation'})) as PresetWithPresetType[]
    return [...publicPresets, ...organisationPresets]
    .sort((a, b) => a.name.localeCompare(b.name))
    .slice((this.presetPageIndex - 1) * 10, this.presetPageIndex * 10)
  }

  private get presets(): DocumentPresetResource[] {
    return this.user?.libraryPresets ?? []
  }

   private get organisationPresets(): DocumentPresetResource[] {
    return organisationDocumentsModule?.presets ?? []
  }

  private get libUpdates(): NotificationResource[] | null {
    if (this.user?.notifications?.data) {
      const presetNotifications = this.user?.notifications?.data.data.filter((notification: NotificationResource) => {
        return notification.type.includes('DocumentsAddedToPreset')
      })

      // Filter out duplicate notifications
      const filteredNotifications = []
      const notificationMap = new Map()
      for (const notification of presetNotifications) {
        if (!notificationMap.has(notification.link)) {
          notificationMap.set(notification.link, true)
          filteredNotifications.push(notification)
        }
      }
      return filteredNotifications
    }

    return null
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get hasData(): boolean {
    return !!this.index && this.index.data.length > 0
  }

  private get headers(): TableElement[] {
    return this.index && this.index.elements ? this.index.elements : []
  }

  private get canCreate(): boolean {
    return userModule.canPerformAction('can_start_project')
  }

  private get projectFormComplete(): boolean {
    return this.create.form.project_name.length > 0 && !!this.create.form.project_type_id
  }

  private get noProjectsSelected(): boolean {
    return this.selectedProjects.length === 0
  }

  // set preset pagination index
  private updatePresetPaginationIndex(index: string): void {
    this.presetPageIndex = Number(index)
  }

  private searchProjectType(): void {
    this.search()
    this.selectedProjects = []
  }

  // Search for project in table
  private search(): void {
    this.setRouteQuery()
    this.getProjects()
  }

  private setSearch(value: string): void {
    this.params.search = value
  }

  // Lifecycle hooks
  private async created(): Promise<void> {
    this.setParamsWithQuery()
    this.getProjects()
    this.getProjectTypes()
    this.getOrganisationPresets()
  }

  private async getProjects(): Promise<void> {
    stateModule.setLoading(true)
    try {
      await projectsModule.get(this.params)
    } finally {
      stateModule.setLoading(false)
    }
  }
  private selectProjectType(id: number): void {
    this.create.form.project_type_id = id
  }

  private async getProjectTypes(): Promise<void> {
    if (userModule.canPerformAction('can_access_projects')) {
      this.create.loading = true
      try {
        const { data } = await this.organisationService.getProjectTypes()
        this.project_types = data
      } finally {
        this.create.loading = false
      }
    }
  }

  private async getOrganisationPresets(): Promise<void> {
    await organisationDocumentsModule.getPresets()
  }

  private destroyed(): void {
    projectsModule.resetState()
  }

  // Methods
  private toggleModal(): void {
    this.resetCreateForm()
    this.removeErrors()
    this.getProjectTypes()
    this.create.open = !this.create.open
  }

  private toggleProjectTypeGroup(): void {
    this.projectTypeGroupExpanded = !this.projectTypeGroupExpanded
    const group = this.$refs['project-type-group'] as Element
    this.$nextTick(() => {
      group.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    })
  }

  private resetCreateForm(): void {
    this.create.form = new ProjectCreateRequest()
  }

  private async createProject(): Promise<void> {
    stateModule.setLoading(true)
    this.create.loading = true
    try {
      const { data } = await this.service.create(this.create.form)
      this.removeErrors()
      this.toggleModal()
      this.$router.push({
        name: 'projects-single-detail',
        params: { project_id: data.id.toString() },
      })
    } catch (e) {

      if (e.errors) {
        this.$set(this.create, 'errors', e.errors)
      }
    } finally {
      stateModule.setLoading(false)
      this.create.loading = false
    }
  }

  private removeError(name: string): void {
    this.$delete(this.create.errors, name)
  }

  private removeErrors(): void {
    this.$set(this.create, 'errors', {})
  }

  private setParamsWithQuery(): void {
    const params = { ...this.params, ...this.$route.query } as IndexParameters
    // params.page = +params.page
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        if (!this.params[param] && params[param]) {
          this.$set(this.params, param, params[param])
        }
      }
    }
  }

  private setRouteQuery(): void {
    const params = { ...this.params }
    this.$router.replace({
      name: this.$route.name || '',
      query: OBJECT_VALUES_TO_STRING(params),
    })
  }

  private getRowElements(document: ProjectCollectionResource): TableDataElement[] {
    const data: TableDataElement[] = []
    if (this.index && this.index.elements) {
      this.index.elements.forEach((element: TableElement) => {
        data.push({
          value: document[element.key as keyof ProjectCollectionResource] as string,
          type: element.type,
        })
      })
    }
    return data
  }

  private setOrder(col: string, dir: string): void {
    this.params.column = col
    this.params.dir = dir
    this.params.page = '1'
    this.setRouteQuery()
    this.getProjects()
  }

  private goToPage(val: string): void {
    this.params.page = val
    this.$nextTick(() => {
      this.getProjects()
    })
  }

  private isSelected(project: ProjectCollectionResource): boolean {
    return !!this.selectedProjects.find((p) => p.id === project.id)
  }

  private updateSelectedProjects(project: ProjectCollectionResource): void {
    const projectIndex = this.selectedProjects.findIndex((p) => p.id === project.id)
    if (projectIndex > -1) {
      this.selectedProjects.splice(projectIndex, 1)
    } else {
      this.selectedProjects.push(project)
    }
  }

  private isDisabledForSelection(project: ProjectCollectionResource): boolean {
    if (this.noProjectsSelected) {
      return false
    }

    return this.selectedProjects[0].project_type !== project.project_type
  }

  private canExport(project: ProjectCollectionResource): boolean {
    return project.canPerformAction('can_edit_project')
  }

  private async exportProjectData(): Promise<void> {
    const projectIds = this.selectedProjects.map((p) => p.id)
    stateModule.setLoading(true)

    try {
      await projectsModule.exportProjectContents(projectIds)

      this.exported = true
    } catch (e) {
      console.error(e)
    } finally {
      stateModule.setLoading(false)
    }
  }

  private closeExportModal(): void {
    this.exported = false
    this.selectedProjects = []
  }

  private closeConfirmArchiveProjectsModal(): void {
    this.confirmArchiveProjectsModal = false
  }

  private confirmArchiveProjects() {
    this.confirmArchiveProjectsModal = true
  }

  private async archiveProjects() {
    stateModule.setLoading(true)
    try {
      await Promise.all(this.selectedProjects.map((project) => project.archive()))
      await this.getProjects()
      this.selectedProjects = []
      this.closeConfirmArchiveProjectsModal()
    } finally {
      stateModule.setLoading(false)
    }

  }
}
